<template>
    <div class="broadcaster">
        <el-table :data="broadcasterTable"
                  style="width: 100%;flex: 1"
                  size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#333',}"
                  :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="video_works" label="视频作品" align="left" width="600">
                <template slot-scope="scope">
                    <div class="video-detail">
                        <div class="video-img" @click="playVideo(scope.row.video_url)">
                            <img :src="scope.row.cover_url" alt="">
                        </div>
                        <div class="video-right">
                            <div class="video-info">
                                <router-link to="" class="video-link" :title="scope.row.desc">{{ scope.row.desc }}
                                </router-link>
                            </div>
                            <div class="publish-time">
                                <span>发布时间：{{scope.row.video_create_time}}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="playback" label="播放量" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.play_count}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="forward_count" label="评论数" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.comment_count}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="share_count" label="转发" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.forward_count}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="danger" circle size="small" @click="playVideo(scope.row.video_url)">
                        <i class="iconfont">&#xe63a;</i>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center" style="padding-bottom: 10px;"
                :current-page="videoPages.currentPageNum"
                :page-size="videoPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="videoPages.total"
                @current-change="videoCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "BroadcasterVideoModule",
        data() {
            return {
                id: this.$route.query.id || '',  //抖音账号id
                broadcasterTable: [],
                //分页
                videoPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted() {
            this.getMyVideoList()
        },
        methods: {
            //视频列表
            getMyVideoList() {
                let param = {
                    paging: '1',
                    page: this.videoPages.currentPageNum,
                    pageSize: this.videoPages.eachPageNum,
                    // id: this.currentAccount
                    account_id :this.$route.query.id,
                }
                this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_video, param, (res) => {
                    if (res.code === 200) {
                        if (res.data.list.length > 0){
                            this.broadcasterTable = res.data.list;
                            this.videoPages.total = res.data.total;
                        }else{
                            this.$message({
                                type: "warning",
                                message: '到视频管理绑定账号点击更新列表按钮获取数据',
                                duration: 1000,
                            });
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            // 视频播放
            playVideo(video_url){
                if(video_url){
                    window.open(video_url)
                }
            },
            //分页
            videoCurrentChange(val) {
                this.videoPages.currentPageNum = val;
                this.getMyVideoList();
            }
        }
    }
</script>

<style scoped lang="scss">
    .broadcaster {
        width: 1260px;
        display: flex;
        flex-direction: column;
        height: 100%;

        .code-title {
            display: flex;
            justify-content: center;
            font-size: 18px;
            font-weight: 400;
            color: #409eff;
        }
    }

    .video-detail {
        display: flex;
        align-items: center;

        .video-img {
            width: 105px;
            height: 130px;
            cursor: pointer;
            img {
                width: 105px;
                height: 130px;
                object-fit: cover;
            }
        }

        .video-right {
            padding-left: 16px;
            flex: 1;

            .video-info {
                line-height: 1.5;

                .video-link {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 100%;
                    display: block;
                    color: #333;
                    padding-bottom: 16px;

                    &:hover {
                        color: #0824CE;
                    }
                }
            }

            .tag {
                padding-bottom: 16px;
                display: flex;

                .tag-title {
                    display: inline-block;
                    min-width: 38px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #999;
                }

                .tag-list {
                    display: inline-block;
                    max-height: 56px;
                    overflow: hidden;

                    span {
                        padding: 0 10px;
                        background: rgba(244, 244, 244, 1);
                        border-radius: 12px;
                        display: inline-flex;
                        margin: 0 10px 6px 0;
                    }
                }
            }
            .publish-time {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999;
            }
        }
    }
    .code-gq {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #333;
        opacity: .1;
        color: #fff;

    }
    ::v-deep .el-table--border::after, ::v-deep .el-table--group::after, ::v-deep .el-table::before {
        background-color: transparent;
    }

    ::v-deep .el-table__body-wrapper {
        min-height:722px;
    }
</style>